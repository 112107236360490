import React from 'react';
import { TH_FACEBOOK_URL, TH_GITHUB_URL, TH_LINKEDIN_URL } from '../../constants/externalURLs';
import s from './Copyright.module.scss';

const currentYear = new Date().getFullYear();
const copyRightContent = `Copyright ©${currentYear} Tech Holding`;

const Copyright = () => (
  <div className={s.container}>
    <div className={s.copyRight}>
      <div className={s.socialContainer}>
        <a target="_blank" rel="noopener noreferrer" href={TH_FACEBOOK_URL}>
          Facebook
        </a>
        <a target="_blank" rel="noopener noreferrer" href={TH_LINKEDIN_URL}>
          LinkedIn
        </a>
        <a target="_blank" rel="noopener noreferrer" href={TH_GITHUB_URL}>
          Github
        </a>
      </div>
      <div className={s.copyRightText}>
        <p>{copyRightContent}</p>
        <div className={s.privacyLink}>
          <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  </div>
);
export default Copyright;
