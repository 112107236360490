import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import THLogo from '../../assets/footer-svgs/th-logo-full-blue.svg';
import renderContent from '../../utils/rich-text-helpers';
import Copyright from '../Copyright';

import s from './HomeFooter.module.scss';

const propTypes = {
  footerDescription: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

const HomeFooter = ({ footerDescription }) => (
  <footer className={s.root}>
    <div className={s.footerTop}>
      <div className={s.container}>
        <div className={s.footerRow}>
          <div className={s.footerLogo}>
            <div className={s.footerLogoImg}>
              <THLogo />
              {footerDescription?.json?.content.map((content) => renderContent(content))}
            </div>
          </div>
          <div className={s.footerMenu}>
            <h5>Company</h5>

            <ul className={s.footerMenuList}>
              <li className={s.footerMenuItem}>
                <Link to="/about">About Us</Link>
              </li>
              <li className={s.footerMenuItem}>
                <Link to="/services">What We Do</Link>
              </li>
              <li className={s.footerMenuItem}>
                <Link to="/work">Case Studies</Link>
              </li>
              <li className={s.footerMenuItem}>
                <Link to="/blog">Blog</Link>
              </li>
              <li className={s.footerMenuItem}>
                <Link to="/careers">Careers</Link>
              </li>
              <li className={s.footerMenuItem}>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={s.footerLine} />
        <div className={s.copyrightContainer}>
          <Copyright />
        </div>
      </div>
    </div>
  </footer>
);

HomeFooter.propTypes = propTypes;
export default HomeFooter;
