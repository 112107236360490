import { useState, useEffect } from 'react';

/**
 * useScrollDirection React custom hook
 * Usage:
 *    const { scrollDirection, prevScrollTop } = useScrollDirection();
 */

/* useScrollDirection custom hook cannot be unit tested until
 * there is a more reliable method from jest or react-testing-library
 */

/* istanbul ignore next */
export default function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop > prevScrollTop) {
      setScrollDirection('down');
    } else if (currentScrollTop < prevScrollTop) {
      setScrollDirection('up');
    }

    // Set previous scroll top to 0 if at the top of page
    // or negative scrolling for Mobile
    setPrevScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { scrollDirection, prevScrollTop };
}
