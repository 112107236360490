import React from 'react';
import classnames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import s from './rich-text-helpers.module.scss';

const renderContent = (content, className) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node) => {
        return (
          <p className={classnames(s.paragraph, className)} key={node.content[0].value}>
            {node.content[0].value}
          </p>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>
            {node.content[0].value}
          </a>
        );
      },
    },
  };

  return documentToReactComponents(content, options);
};

export default renderContent;
