import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import AOS from 'aos';
import useScrollDirection from '../../utils/hooks/useScrollDirection';
import Button from '../Button';
import Menu from './Menu';
import BrandLogo from './brand-logo.svg';
import 'aos/dist/aos.css';
import s from './Header.module.scss';

const canUseDOM = typeof window !== 'undefined' && window.document;

if (canUseDOM) {
  AOS.init({
    // Global settings:
    offset: 10, // offset (in px) from the original trigger point
    duration: 500, // values from 0 to 3000, with step 50ms
    once: true, // whether animation should happen only once - while scrolling down
  });
}

const propTypes = {
  currentPage: PropTypes.string,
  logoClass: PropTypes.string,
  layoutStyle: PropTypes.shape({}),
};

const defaultProps = {
  currentPage: '',
  logoClass: '',
  layoutStyle: {},
};

const Header = ({ currentPage, logoClass, layoutStyle }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollDirection, prevScrollTop } = useScrollDirection();

  const handleModalOpen = () => {
    setIsMenuOpen(true);
  };

  const handleModalClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <header
      id="root"
      style={layoutStyle}
      className={classnames(s.root, s[logoClass], {
        [s.transparentBG]: prevScrollTop <= 10,
        [s.hiddenHeader]: scrollDirection === 'down',
      })}
    >
      <div className={s.container}>
        <div className={s.left}>
          <Link aria-label="Home" to="/">
            <BrandLogo className={s.logo} />
          </Link>
        </div>
        <div className={s.right}>
          <Link className={currentPage === '/about' ? s.selected : ''} to="/about">
            <Button theme="opaque" className={s.navButton}>
              About Us
            </Button>
          </Link>
          <Link className={currentPage.includes('/services') ? s.selected : ''} to="/services">
            <Button theme="opaque" className={s.navButton}>
              What We Do
            </Button>
          </Link>
          <Link
            className={
              currentPage === '/work' || currentPage.includes('/casestudy') ? s.selected : ''
            }
            to="/work"
          >
            <Button theme="opaque" className={s.navButton}>
              Case Studies
            </Button>
          </Link>
          <Link
            className={[s.blog, currentPage === '/blog' ? s.selected : ''].join(' ')}
            to="/blog"
          >
            <Button theme="opaque" className={s.navButton}>
              Blog
            </Button>
          </Link>
          <Link className={currentPage.includes('/careers') ? s.selected : ''} to="/careers">
            <Button theme="opaque" className={s.navButton}>
              Careers
            </Button>
          </Link>
          <Link className={s.contact} to="/contact">
            <Button theme="opaque" className={s.navButton}>
              Contact Us
            </Button>
          </Link>
          <button
            className={s.hamburger}
            type="button"
            tabIndex="0"
            onKeyPress={handleModalOpen}
            onClick={handleModalOpen}
            aria-label="Open the menu"
          />
        </div>
      </div>
      <Menu isOpen={isMenuOpen} currentPage={currentPage} onModalClose={handleModalClose} />
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
