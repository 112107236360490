import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import s from './LegalPopup.module.scss';

const LegalPopup = () => {
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    setClosed(localStorage.getItem('closed_legal_popup'));
  }, []);

  const closePopup = () => {
    setClosed(true);
    localStorage.setItem('closed_legal_popup', true);
  };

  return (
    <div className={classnames(s.root, closed && s.hidden)}>
      <p>
        By using this site, you agree to the
        <Link to="/privacy-policy">Privacy Policy.</Link>
      </p>
      <button
        type="button"
        aria-label="Close privacy policy notification"
        className={s.closeButton}
        onClick={closePopup}
      />
    </div>
  );
};

export default LegalPopup;
