/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import {
  genEmailLink,
  TH_EMAIL_ADDRESS,
  TH_FACEBOOK_URL,
  TH_GITHUB_URL,
  TH_LINKEDIN_URL,
} from '../../../constants/externalURLs';
import s from './Menu.module.scss';

const links = [
  { text: 'About Us', href: '/about' },
  { text: 'What We Do', href: '/services' },
  { text: 'Case Studies', href: '/work' },
  { text: 'Blog', href: '/blog' },
  { text: 'Careers', href: '/careers' },
  { text: 'Contact Us', href: '/contact' },
];

ReactModal.setAppElement('body');

const propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  currentPage: PropTypes.string,
};

const defaultProps = {
  isOpen: false,
  onModalClose: () => {},
  currentPage: '',
};

const Menu = ({ isOpen, onModalClose, currentPage }) => {
  const handleModalOpen = () => {
    document.body.style.overflow = 'hidden';
  };
  const restoreScroll = () => {
    document.body.removeAttribute('style');
  };
  const handleModalClose = () => {
    restoreScroll();
    onModalClose();
  };

  return (
    <ReactModal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      className={s.modal}
      onAfterOpen={handleModalOpen}
    >
      <nav className={s.modalRoot}>
        <main className={`${s.container} ${s.hexPattern}`}>
          <article className={s.subContainer}>
            <div className={s.contentContainer}>
              <div className={s.closeContainer}>
                <button
                  type="button"
                  aria-label="Close menu"
                  className={s.closeButton}
                  onClick={handleModalClose}
                />
              </div>
              <section className={s.linkContainer} data-aos="fade-up" data-aos-delay="200">
                <ul className={s.links}>
                  {links.map((link) => (
                    <li key={`${Math.random()}`}>
                      <Link
                        className={currentPage === link.href ? s.selected : ''}
                        to={link.href}
                        key={link.href}
                        onClick={restoreScroll}
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
              <section className={s.socialContainer}>
                <div className={s.socialInnerContainer}>
                  <aside className={s.collaborate} data-aos="fade-up" data-aos-delay="400">
                    <h2>Let’s collaborate!</h2>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={genEmailLink(TH_EMAIL_ADDRESS)}
                      >
                        {TH_EMAIL_ADDRESS}
                      </a>
                    </p>
                  </aside>
                  <aside className={s.socialLinks} data-aos="fade-up" data-aos-delay="600">
                    <a target="_blank" rel="noopener noreferrer" href={TH_FACEBOOK_URL}>
                      Facebook
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={TH_LINKEDIN_URL}>
                      LinkedIn
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={TH_GITHUB_URL}>
                      Github
                    </a>
                  </aside>
                </div>
              </section>
            </div>
          </article>
        </main>
      </nav>
    </ReactModal>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;
export default Menu;
