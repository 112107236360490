import React from 'react';
import PropTypes from 'prop-types';
import s from './Button.module.scss';

const propTypes = {
  theme: PropTypes.oneOf(['grey', 'blue', 'opaque', 'text']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  theme: 'grey',
  onClick: () => {},
  className: '',
};

const Button = ({ theme, onClick, className, children }) => (
  <button type="button" onClick={onClick} className={[s.btn, s[theme], className].join(' ')}>
    {children}
  </button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
